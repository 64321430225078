.drawing-area {
    position: absolute;
    top: 60px;
    left: 122px;
    z-index: 10;
    width: 200px;
    height: 400px;
    border: 1px solid #ccc;
}

.canvas-container {
    width: 200px;
    height: 400px;
    position: relative;
    user-select: none;
}

#tshirt-div {
    width: 452px;
    height: 548px;
    position: relative;
    background-color: #fff;
}

#canvas {
    position: absolute;
    width: 200px;
    height: 400px;
    left: 0px;
    top: 0px;
    user-select: none;
    cursor: default;
}
